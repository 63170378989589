//grid
$xxsm: 374px;
$xsm: 459px;
$sm: 639px;
$md: 767px;
$lg: 1023px;
$xl: 1199px;
$xxl: 1399px;

//fonts
$font-1: TTSupermolot, Arial, sans-serif;
$font-2: Circle, Arial, sans-serif;

//colors
$color-1: #000;
$color-2: #fff;
$color-3: #fff;
$color-4: #fff;
$color-5: #fff;

// timing
$time-sm: 300ms;
$time-md: 500ms;
$time-lg: 800ms;
$time-xl: 1100ms;
