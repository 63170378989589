.header {
  position: relative;
  z-index: 2;
  height: 165px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: TTSupermolot;
  @include media(1450px) {
    height: 125px;
  }
  @include media(1024px) {
    height: 80px;
  }
  &__inner {
    display: flex;
    justify-content: space-between;
  }
  &__col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &--logo {
    }
    &--nav {
    }
    &--cta {
    }
  }
  &__logo-wrap {
    width: 150px;
    position: relative;
    @include media(1200px) {
      width: 120px;
    }
    @include media(1024px) {
      width: 88px;
    }
    a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
    }
  }
  &__logo-img {
    width: 100%;
  }
  &__nav-wrap {
    @include media(1024px) {
      display: none;
    }
    ul {
      display: flex;
      li {
        position: relative;
        margin-right: 30px;
        @include media(1200px) {
          margin-right: 15px;
        }
        &:hover .sub-nav {
          opacity: 1;
          pointer-events: all;
        }
        &:hover a {
          background-color: #F2F2F2;
        }
        &:hover .sub-nav-here > svg {
          transform: translateY(-50%) rotate(0);
        }
        &:last-child {
          margin-right: 0;
        }
        a {
          font-size: 20px;
          font-weight: bold;
          padding: 5px;
          display: inline-block;
          transition: background-color .3s, color .3s;
          @include media(1450px) {
            font-size: 18px;
          }
          @include media(1200px) {
            font-size: 16px;
          }
          &:hover {
            background-color: #F2F2F2;
          }
        }
      }
      .sub-nav {
        top: 100%;
        left: 0;
        position: absolute;
        display: block;
        width: 367px;
        background-color: #F2F2F2;
        opacity: 0;
        pointer-events: none;
        transition: opacity .3s;
        li {
          margin: 0;
        }
        a {
          width: 100%;
          padding: 10px 23px;
          &:hover {
            color: #fff;
            background-color: #e53e2a;
          }
        }
      }
    }
  }
  &__cta-wrap {
    @include media(1024px) {
      display: none;
    }
  }
  &__cta {
    padding: 0 30px;
    height: 60px;
    line-height: 52px;
    border: 4px solid #e53e2a;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    transition: background-color .3s, color .3s;
    @include media(1450px) {
      font-size: 18px;
      padding: 0 15px;
    }
    @include media(1200px) {
      font-size: 16px;
      height: 40px;
      padding: 0 10px;
      line-height: 32px;
    }
    &:hover{
      background-color: #e53e2a;
      color: #fff;
    }
  }
  .sub-nav-here {
    position: relative;
    padding-left: 20px;
    svg {
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
      transition: transform .3s;
    }
  }
  .mobile-list-wrap {
    display: none;
    @include media(1024px) {
      display: flex;
      align-items: center;
    }
  }
  .mobile-list {
    display: flex;
    li {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.header-mob-nav {
  opacity: 0;
  transform: translateX(100%);
  padding: 10px 40px;
  overflow-y: auto;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  background-color: #000;
  color: #fff;
  pointer-events: none;
  transition: opacity .5s ease-in-out, transform .5s ease-in-out;
  &.active{
    opacity: 1;
    pointer-events: all;
    transform: translateX(0);
  }
  &__close{
    position: absolute;
    z-index: 2;
    top: 25px;
    right: 50px;
    display: block;
    width: 44px;
    height: 44px;
    background-image: url("/img/close.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    transition: opacity .3s;
    @include media(1024px) {
      width: 25px;
      height: 25px;
      top: 20px;
      right: 20px;
    }
    @include media(768px) {
      //top: 10px;
      //right: 10px;
    }
    &:hover {
      opacity: .7;
    }
  }
  &__logo {
    width: 150px;
    display: block;
    margin: 25px auto 40px;
  }
  &__list {
    margin-bottom: 61px;
    li {
      text-align: center;
      margin-bottom: 17px;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        font-size: 32px;
        @include media(768px){
          font-size: 24px;
        }
      }
      .sub-nav{
        max-height: 0;
        transition: max-height .5s;
        overflow: hidden;
        li{
          &:first-child{
            padding-top: 17px;
          }
        }
      }
      .sub-nav-here {
        position: relative;
        padding-left: 20px;
        svg {
          position: absolute;
          left: 5px;
          top: 50%;
          transform: translateY(-50%) rotate(180deg);
          transition: transform .3s;
        }
      }
      &.active{
        .sub-nav{
          max-height: 350px;
        }
        svg {
          transform: translateY(-50%) rotate(0);
        }
      }
    }
  }
  &__phone-wrap{
    margin-bottom: 24px;
    text-align: center;
  }
  &__phone{
    font-size: 30px;
    color: #E43E28;
  }
  &__socials{
    display: flex;
    align-items: center;
    justify-content: center;
    li{
      margin-right: 15px;
      &:last-child{
        margin-right: 0;
      }
      img{
        width: 44px;
      }
    }
  }
}