//include third-party fonts

@mixin font-face($font-name, $file-name, $font-weight, $font-style) {
  @font-face {
    font-family: quote($font-name);
    src: url("../fonts/"+ $file-name + ".woff2") format("woff2"),
    url("../fonts/"+ $file-name + ".woff") format("woff");
    font-weight: $font-weight;
    font-style: $font-style;
    font-display: swap;
  }
}

@include font-face(TTSupermolot, TTSupermolotNeue-ExpDBold, 800, normal);

@include font-face(Circle, CIRCE-LIGHT, 300, normal);
@include font-face(Circle, CIRCE-REGULAR, 400, normal);
@include font-face(Circle, CIRCE-BOLD, 800, normal);


// @include font-face(GTAmerica, GTAmerica-CondensedMedium, 500, normal);
