html, body {
  font-size: 18px;

  @media (max-width: $xl) {
    font-size: 17px;
  }

  @media (max-width: $lg) {
    font-size: 16px;
  }

  @media (max-width: $md) {
    font-size: 15px;
  }
}
