.footer{
  background-color: #1A1A1A;
  &__inner{
    height: 163px;
    display: flex;
    align-items: center;
  }
  &__logo-wrap{
    margin: 0 auto;
    width: 130px;
    position: relative;
    a{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
    }
  }
  &__logo{
    width: 100%;
    display: block;
  }
}