html, body {
  font-family: $font-1;
  font-weight: 400;
  color: $color-1;
}

main{
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

img, video {
  max-width: 100%;
  height: auto;
}

button{
  cursor: pointer;
}

.title {
  font-size: rem-calc(32);
  font-weight: 500;
  line-height: 1.2em;
  margin-bottom: 1.05em;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.text-container {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.45em;
  letter-spacing: 0;

  p, ul, ol {
    margin-bottom: 1em;
  }
}

.section-title{
  position: relative;
  font-size: 56px;
  font-weight: bold;
  line-height: (67/56);
  @include media(1450px){
    font-size: 42px;
  }
  @include media(768px){
    font-size: 29px;
  }
  span{
    color: #e43e28;
  }
}

.section-title-small{
  position: relative;
  font-size: 42px;
  font-weight: bold;
  line-height: (67/56);
  @include media(1450px){
    font-size: 30px;
  }
  @include media(1024px){
    font-size: 26px;
  }
  span{
    color: #e43e28;
  }
}

.border-cta{
  padding: 15px 40px;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  border: solid 3px #e53e2a;
  cursor: pointer;
  transition: color .3s, background-color .3s;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @include media(768px){
    padding: 0 25px;
    font-size: 18px;
  }
  &:hover{
    color: #fff;
    background-color: #e53e2a;
  }
}

.slider-btn{
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  &--prev{
    margin-right: 9px;
    width: 58px;
    height: 58px;
    border: 1px solid #000;
    background-color: #fff;
    svg{
      position: absolute;
      top: 50%;
      left: calc(50% - 1px);
      transform: translate(-50%, -50%);
    }
  }
  &--next{
    width: 64px;
    height: 64px;
    background-color: #e53e2a;
    svg{
      position: absolute;
      top: 50%;
      left: calc(50% + 2px);
      transform: translate(-50%, -50%);
    }
  }
}

.desktop{
  @include media(768px){
    display: none!important;
  }
}

.mobile{
  display: none!important;
  @include media(768px){
    display: block!important;
  }
}

.tablet{
  display: none!important;
  @include media(1024px){
    display: block!important;
  }
}

.scrollStop{
  overflow: hidden;
}

.contact-form__message{
  max-width: 400px;
  margin: 5px auto 0;
  font-size: 14px;
  text-align: center;
  &.green{
    color: green;
  }
  &.red{
    color: red;
  }
}