.hero {
  font-family: $font-1;
  //border: 1px solid red;
  &__inner-container {
    width: 100%;
    max-width: 1500px;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__slider-wrap {
    position: relative;
  }
  &__slider {
    position: relative;
    overflow: hidden;
    .static-wrapper {
      .hero {
        &__slide-inner {
          max-width: 770px;
        }
        &__slide-title {
          font-size: 40px;
          margin-bottom: 40px;
          @include media(1024px) {
            font-size: 34px;
          }
        }
        &__slide-red-text {
          margin-bottom: 7px;
        }
        &__slide {
          &--toning {
            .hero__slide-title {
              max-width: 500px;
            }
          }
        }
      }
    }
  }
  &__swiper-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  &__slide {
    overflow: hidden;
    width: 100%;
    height: calc(100vh - 165px);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    @include media(1450px) {
      height: calc(100vh - 125px);
    }
    @include media(1024px) {
      justify-content: flex-start;
      height: calc(100vh - 80px);
    }
    .container--big {
      margin-top: -75px;
      @include media(1024px) {
        margin-top: 100px;
      }
      @include media-h(500px) {
        margin-top: 75px;
      }
    }
    &--main {
      .hero__slide-bg {
        @include media(1450px) {
          right: auto;
          left: 50%;
        }
        @include media(1024px) {
          top: auto;
          bottom: 20px;
          right: 0;
          left: 0;
          margin: 0 auto;
          transform: none;
          max-height: 50vh;
          height: auto;
        }
      }
    }
    &--wrapping {
      .hero__slide-bg {
        top: auto;
        right: auto;
        left: 35%;
        bottom: 0;
        transform: none;
        @include media(1024px) {
          top: auto;
          bottom: 20px;
          right: 0;
          margin: 0 auto;
          transform: none;
          max-height: 50vh;
          height: auto;
          left: 5%;
          max-width: unset;
        }
      }
    }
    &--security {
      .hero {
        &__slide-bg {
          top: auto;
          right: auto;
          left: 42%;
          bottom: 75px;
          transform: none;
          @include media(1024px) {
            top: auto;
            bottom: 70px;
            right: 0;
            margin: 0 auto;
            transform: none;
            max-height: 50vh;
            height: auto;
            left: 5%;
            max-width: unset;
          }
          &--helper {
            z-index: 2;
            left: 52%;
            max-height: 53vh;
            @include media(1600px) {
              left: 46%;
            }
            @include media(1024px) {
              z-index: unset;
              bottom: 20px;
              left: 10%;
              max-height: 40vh;
            }
          }
        }
      }
    }
    &--alarm {
      .hero {
        &__slide-bg {
          z-index: 2;
          top: auto;
          right: auto;
          bottom: 0px;
          left: 50%;
          transform: none;
          @include media(1450px) {
            height: 480px;
          }
          @include media(1024px) {
            z-index: unset;
            top: auto;
            bottom: 20px;
            right: 0;
            margin: 0 auto;
            transform: none;
            max-height: 50vh;
            height: auto;
            left: 5%;
            max-width: unset;
          }
        }
      }
    }
    &--soundproofing {
      .hero {
        &__slide-bg {
          z-index: 2;
          top: calc(50% + 35px);
          @include media(1450px) {
            right: auto;
            left: 50%;
          }
          @include media(1024px) {
            top: auto;
            bottom: 20px;
            right: 0;
            left: 0;
            margin: 0 auto;
            transform: none;
            max-height: 50vh;
            height: auto;
          }
        }
        &__slide-bg-text {
          left: -115px;
          @include media(1600px) {
            left: unset;
          }
          @include media(1024px) {
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
    &--optics {
      .hero {
        &__slide-bg {
          z-index: 2;
          right: -10%;
          @include media(1450px) {
            right: auto;
            left: 50%;
          }
          @include media(1200px) {
            left: 57%;
          }
          @include media(1024px) {
            top: auto;
            bottom: 20px;
            right: 0;
            left: 0;
            margin: 0 auto;
            transform: none;
            max-height: 50vh;
            height: auto;
          }
        }
      }
    }
    &--toning {
      .hero {
        &__slide-bg {
          z-index: 2;
          top: auto;
          right: 0;
          left: auto;
          bottom: 0;
          transform: none;
          @include media(1450px) {
            height: 480px;
          }
          @include media(1200px) {
            height: 400px;
          }
          @include media(1024px) {
            z-index: unset;
            top: auto;
            bottom: 20px;
            right: 0;
            left: 0;
            margin: 0 auto;
            transform: none;
            max-height: 50vh;
            height: auto;
          }
        }
      }
    }
  }
  &__slide-bg {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
    @include media(1600px) {
      max-height: 67vh;
    }
    @include media(1024px) {
      top: auto;
      bottom: 20px;
      right: 0;
      left: 0;
      margin: 0 auto;
      transform: none;
      max-height: 50vh;
      height: auto;
    }
    @include media-h(500px) {
      display: none;
    }
  }
  &__slide-inner {
    position: relative;
    z-index: 1;
    font-family: $font-1;
    max-width: 670px;
    @include media(1024px) {
      z-index: 3;
      text-align: center;
      margin: 0 auto;
    }
  }
  &__slide-bg-text {
    position: absolute;
    z-index: -1;
    top: -124px;
    line-height: 1.2;
    font-size: 160px;
    font-weight: bold;
    //color: rgba(230, 229, 229, .5);
    color: rgba(220, 220, 220, .5);
    text-transform: uppercase;
    @include media(1600px) {
      font-size: 130px;
      top: -105px;
    }
    @include media(1024px) {
      font-size: 74px;
      top: -60px;
      min-width: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &__slide-red-text {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: normal;
    color: #e43e28;
    @include media(1450px) {
      font-size: 17px;
    }
    @include media(1024px) {
      margin-bottom: 0;
    }
  }
  &__slide-title {
    margin-bottom: 45px;
    font-size: 56px;
    font-weight: bold;
    @include media(1600px) {
      font-size: 46px;
      margin-bottom: 30px;
    }
    @include media(1024px) {
      font-size: 34px;
      margin-bottom: 20px;
    }
  }
  &__slide-cta-wrap {
  }
  &__slide-cta {
    display: inline-block;
    padding: 15px 39px;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    background-color: #e53e2a;
    border: solid 3px #e53e2a;
    transition: background-color .3s, color .3s;
    &:hover{
      background-color: transparent;
      color: #e53e2a;
    }
  }
  &__slider-bottom {
    position: absolute;
    bottom: 65px;
    left: 0;
    width: 100%;
    @include media(1600px) {
      bottom: 20px;
    }
    @include media(1024px) {
      position: unset;
    }
  }
  &__slider-nav {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    bottom: 0;
    @include media(1024px) {
      margin-bottom: 0;
    }
  }
  &__slider-btn {
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    &--prev {
      margin-right: 9px;
      width: 58px;
      height: 58px;
      border: 1px solid #000;
      @include media(1024px) {
        position: absolute;
        top: 35%;
        left: 10px;
        width: 45px;
        height: 45px;
      }
      svg {
        position: absolute;
        top: 50%;
        left: calc(50% - 1px);
        transform: translate(-50%, -50%);
      }
    }
    &--next {
      width: 64px;
      height: 64px;
      background-color: #e53e2a;
      @include media(1024px) {
        position: absolute;
        top: 35%;
        right: 10px;
        width: 45px;
        height: 45px;
      }
      svg {
        position: absolute;
        top: 50%;
        left: calc(50% + 2px);
        transform: translate(-50%, -50%);
      }
    }
  }
  &__bottom {
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }
  &__socials {
    display: flex;
    align-items: center;
    @include media(1024px) {
      display: none;
    }
  }
  &__socials-title {
    margin-right: 12px;
    font-size: 20px;
    font-weight: bold;
  }
  &__socials-list {
    display: flex;
    li {
      margin-right: 35px;
      a {
        img {
        }
      }
    }
  }
  .border-cta {
    @include media(1024px) {
      display: none;
    }
  }
}

.services {
  padding-top: 96px;
  padding-bottom: 229px;
  @include media(1450px) {
    padding-bottom: 129px;
  }
  @include media(1200px) {
    padding-top: 96px;
    padding-bottom: 72px;
  }
  &__inner {
  }
  &__title {
    margin-bottom: 60px;
    text-align: center;
    @include media(1450px) {
      margin-bottom: 93px;
    }
  }
  &__grid {
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    width: calc(33.33% - 26px);
    margin: 20px 39px 20px 0;
    @include media(1200px) {
      width: calc(33.33% - 20px);
      margin: 20px 30px 20px 0;
    }
    @include media(1024px) {
      width: calc(50% - 15px);
      margin: 15px 30px 15px 0;
    }
    @include media(768px) {
      width: 100%;
      max-width: 470px;
      margin: 20px auto;
    }
    &:nth-child(3n) {
      margin-right: 0;
      @include media(1024px) {
        margin-right: 30px;
      }
      @include media(768px) {
        margin: 20px auto;
      }
    }
    &:nth-child(2n) {
      @include media(1024px) {
        margin-right: 0;
      }
      @include media(768px) {
        margin: 20px auto;
      }
    }
  }
  &__item-link {
    &:hover .hover-item {
      max-width: 70px;
      @include media(1024px) {
        max-width: 56px;
      }
    }
    &:hover .services__item-title-inner {
      max-width: calc(100% - 70px);
      @include media(1024px) {
        max-width: calc(100% - 56px);
      }
    }
    &:hover .services__item-title-inner {
      background-color: #e53e2a;
    }
  }
  &__item-img-wrap {
    height: 360px;
    position: relative;
    @include media(1450px) {
      height: 240px;
    }
    //@include media(1200px) {
    //  height: 240px;
    //}
    @include media(768px) {
      height: 280px;
    }
  }
  &__item-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: .1;
  }
  &__item-img {
    display: block;
    height: 100%;
    width: 100%;
    @include object-fit(cover);
  }
  &__item-title {
    overflow: hidden;
    display: flex;
  }
  &__item-title-inner {
    overflow: hidden;
    max-width: 100%;
    width: 100%;
    padding: 0 10px;
    height: 70px;
    line-height: 70px;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: $font-2;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    background-color: #000;
    color: #fff;
    transition: max-width .3s linear, background-color .3s linear;
    @include media(1450px) {
      font-size: 20px;
    }
    @include media(1024px) {
      height: 56px;
      line-height: 56px;
    }
  }
  .hover-item {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 0;
    width: 70px;
    height: 70px;
    transition: max-width .3s linear;
    @include media(1024px) {
      width: 56px;
      height: 56px;
    }
  }
}

.certificates {
  padding: 73px 0 122px;
  background-color: #1a1a1a;
  color: #fff;
  @include media(1300px) {
    padding: 73px 0 73px;
  }
  &__title {
    margin-bottom: 70px;
    text-align: center;
    @include media(768px) {
      margin-bottom: 51px
    }
  }
  &__inner {
    font-family: $font-2;
    display: flex;
    @include media(1300px) {
      flex-direction: column-reverse;
    }
    @include media(768px) {
      display: block;
    }
  }
  &__col {
    &--content {
      padding-right: 50px;
      width: calc(100% - 530px);
      @include media(1300px) {
        width: 100%;
        padding: 0;
      }
      @include media(768px) {
        margin-bottom: 30px;
      }
    }
    &--slider {
      width: 530px;
      @include media(1300px) {
        margin: 0 auto 50px;
      }
      @include media(600px) {
        margin: 0 auto;
        width: auto;
        max-width: 369px;
      }
    }
  }
  &__grid {
    margin-bottom: 35px;
    display: flex;
    flex-wrap: wrap;
    @include media(1300px) {
      margin-bottom: 0;
    }
    @include media(768px) {
      margin-bottom: 50px;
    }
  }
  &__grid-item {
    margin: 30px 28px 30px 0;
    display: flex;
    width: calc(50% - 14px);
    @include media(1300px) {
      margin: 30px 40px 30px 0;
      width: calc(50% - 20px);
    }
    @include media(768px) {
      width: 100%;
      margin: 20px 0;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  &__grid-icon-wrap {
    width: 83px;
    img {
      @include media(768px) {
        margin: 0 auto;
        display: block;
      }
    }
  }
  &__grid-content {
    width: calc(100% - 83px);
  }
  &__grid-title {
    Margin-bottom: 13px;
    font-weight: bold;
    font-size: 22px;
    line-height: 1;
  }
  &__grid-text {
    max-width: 243px;
    font-size: 16px;
    line-height: 1;
    @include media(1300px) {
      max-width: unset;
    }
  }
  &__logos {
    display: flex;
    align-items: center;
    @include media(768px) {
      flex-wrap: wrap;
    }
    li {
      margin-right: 56px;
      @include media(768px) {
        width: calc(50% - 15px);
        margin-right: 30px;
      }
      &:last-child {
        margin-right: 0;
        @include media(768px) {
          margin-right: 30px;
        }
      }
      &:nth-child(2n) {
        @include media(768px) {
          margin-right: 0;
        }
      }
      img {
        @include media(768px) {
          display: block;
          max-width: 236px;
          margin: 0 auto;
        }
        @include media(600px) {
          max-width: 100%;
        }
      }
    }
  }
  &__sliders-wrap {
    display: flex;
    justify-content: space-between;
    @include media(600px) {
      display: block;
    }
  }
  &__slider {
    width: 370px;
    position: relative;
    overflow: hidden;
    @include media(600px) {
      margin-bottom: 20px;
      width: 100%;
    }
  }
  &__swiper-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  &__slide {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    flex-shrink: 0;
    height: 515px;
    @include media(600px) {
      height: auto;
    }
    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto;
    }
  }
  &__slider-extra {
    position: relative;
    overflow: hidden;
    width: 116px;
    height: 515px;
    @include media(600px) {
      width: 100%;
      height: auto;
    }
  }
  &__swiper-wrapper-extra {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    @include media(600px) {
      flex-direction: row;
    }
  }
  &__slide-extra {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    flex-shrink: 0;
    @include media(600px) {
      //height: 165px;
    }
    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto;
    }
  }
  &__slider-nav {
    display: flex;
    margin-right: 30px;
  }
  &__footer {
    margin-top: 24px;
    display: flex;
    align-items: center;
  }
  &__footer-text {
    font-size: 20px;
    font-weight: bold;
    font-family: $font-2;
  }
}

.specialists {
  padding: 200px 0;
  @include media(1200px) {
    padding: 100px 0;
  }
  &__inner {
    display: flex;
    @include media(1200px) {
      display: block;
      width: 100%;
      max-width: 745px;
      margin: 0 auto;
    }
  }
  &__col {
    &--content {
      padding-right: 68px;
      width: calc(100% - 745px);
      @include media(1400px) {
        width: calc(100% - 545px);
      }
      @include media(1200px) {
        text-align: center;
        width: 100%;
        padding: 0;
      }
    }
    &--grid {
      width: 745px;
      @include media(1400px) {
        width: 545px;
      }
      @include media(1200px) {
        width: 100%;
        max-width: 745px;
      }
    }
  }
  &__pre-title {
    margin-bottom: 14px;
    font-size: 20px;
    font-weight: bold;
    color: #e43e28;
  }
  &__title {
    margin-bottom: 21px;
  }
  &__text {
    margin-bottom: 15px;
    max-width: 508px;
    font-family: $font-2;
    font-size: 18px;
    line-height: 1.11;
    color: #000;
    opacity: 0.8;
    @include media(1400px) {
      max-width: unset;
    }
  }
  &__numbers {
    display: flex;
    flex-wrap: wrap;
    .sn {
      width: calc(50% - 20px);
      margin: 42px 40px 42px 0;
      &:nth-child(2n) {
        margin-right: 0;
      }
      &__number {
        font-size: 56px;
        font-weight: bold;
        color: #e43e28;
        @include media(1600px) {
          font-size: 46px;
        }
        div {
          position: relative;
          display: inline-block;
        }
        img {
          max-width: 50px;
          position: absolute;
          bottom: 70%;
          left: 80%;
        }
      }
      &__text {
      }
    }
  }
  &__grid {
    display: flex;
    justify-content: space-between;
    @include media(800px) {
      flex-direction: column-reverse;
    }
  }
  &__card {
    position: relative;
    img {
      display: block;
      width: 100%;
      height: 100%;
      @include object-fit(cover);
    }
  }
  &__card-info {
    width: calc(100% - 64px);
    padding: 17px 17px 17px 50px;
    position: absolute;
    bottom: 30px;
    right: 0;
    color: #fff;
    background-color: #e43e28;
    @include media(1400px) {
      width: calc(100% - 25px);
      padding: 17px;
      position: absolute;
      bottom: 20px;
    }
    @include media(800px) {
      text-align: center;
      width: 100%;
      bottom: 0;
    }
  }
  &__name {
    margin-bottom: 8px;
    font-family: $font-1;
    font-size: 15px;
    font-weight: bold;
  }
  &__position {
    font-family: $font-2;
    font-size: 14px;
    font-weight: normal;
  }
  &__grid-col {
    &:first-child {
      width: 320px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include media(1400px) {
        width: 250px;
      }
      @include media(1200px) {
        width: 320px;
      }
      @include media(800px) {
        width: 100%;
      }
      .specialists__card {
        width: 100%;
        height: calc(50% - 17px);
        @include media(1400px) {
          height: calc(50% - 12px);
        }
        @include media(1200px) {
          height: calc(50% - 17px);
        }
        @include media(800px) {
          height: auto;
          margin-bottom: 44px;
        }
        &:last-child {
          @include media(800px) {
            margin-bottom: 0;
          }
        }
      }
    }
    &:last-child {
      width: 400px;
      @include media(1400px) {
        width: 270px;
      }
      @include media(1200px) {
        width: 400px;
      }
      @include media(800px) {
        width: 100%;
      }
      .specialists {
        &__card {
          width: 100%;
          height: 100%;
          @include media(800px) {
            height: auto;
            margin-bottom: 44px;
          }
        }
        &__card-info {
          padding: 23px 23px 23px 65px;
          bottom: 50px;
          background-color: #000;
          @include media(800px) {
            padding: 23px;
            bottom: 0;
          }
        }
        &__name {
          font-size: 20px;
        }
        &__position {
          font-size: 18px;
        }
      }
    }
  }
}

.works {
  &__inner {
    padding: 100px 0 135px;
    @include media(1024px) {
      padding: 50px 0 75px;
    }
    @include media(400px) {
      width: 95%;
      margin: 0 auto;
    }
  }
  &__title {
    text-align: center;
  }
  &__slider-header {
    margin-bottom: 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &__slider-nav {
    margin-right: 10px;
    .slider-btn {
      &--prev {
        margin-right: 3px;
        width: 29px;
        height: 29px;
        border: 2px solid #000;
        background-color: #fff;
        svg {
          width: 5px;
          height: 9px;
          left: 50%;
        }
      }
      &--next {
        width: 32px;
        height: 32px;
        border: 2px solid #e53e2a;
        background-color: #fff;
      }
    }
  }
  &__slider-counter {
    font-size: 26px;
    font-weight: bold;
    span {
      font-size: 36px;
    }
  }
  &__slider {
    position: relative;
    overflow: hidden;
  }
  &__swiper-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  &__slide {
    position: relative;
    width: 360px;
    flex-shrink: 0;
    @include media(400px) {
      width: 100%;
    }
    &:hover .works__slide-hover {
      opacity: 1;
    }
    &:hover .works__slide-img {
      transform: scale(1.2);
    }
  }
  &__slide-img-wrap {
    position: relative;
    height: 280px;
    overflow: hidden;
  }
  &__slide-img {
    width: 100%;
    height: 100%;
    @include object-fit(cover);
    transition: transform 20s linear;
  }
  &__slide-hover {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s linear;
  }
  &__slide-cta {
    margin: 0 auto;
    padding: 0 20px;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    background-color: #e53e2a;
    height: 54px;
    border: solid 3px #e53e2a;
    transition: background-color .3s, color .3s;
    &:hover{
      background-color: transparent;
      color: #e53e2a;
    }
  }
  &__slide-text {
    padding: 25px 0;
  }
  &__slide-title {
    margin-bottom: 9px;
    font-size: 20px;
    font-weight: bold;
  }
  &__service {
    font-family: $font-2;
    font-size: 20px;
    font-weight: normal;
  }
}

.w-video {
  padding-bottom: 125px;
  @include media(1400px) {
    padding-bottom: 105px;
  }
  @include media(1024px) {
    padding-bottom: 50px;
  }
  &__title {
    margin-bottom: 88px;
    text-align: center;
    @include media(1024px) {
      margin-bottom: 70px;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    @include media(1024px) {
      margin: 0 auto;
      width: 100%;
      max-width: 450px;
      display: block;
    }
  }
  &__item {
    width: calc(50% - 44px);
    margin: 35px 88px 35px 0;
    @include media(1600px) {
      width: calc(50% - 24px);
      margin: 15px 44px 15px 0;
    }
    @include media(1024px) {
      margin: 0 0 35px 0;
      width: 100%;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  &__frame-wrap {
    position: relative;
    height: 320px;
    cursor: pointer;
    @include media(1600px) {
      height: 295px;
    }
    @include media(500px) {
      height: 234px;
    }
    iframe {
      width: 100%;
      height: 100%;
    }
    &:hover .ytp-large-play-button-bg {
      fill: #f00;
      fill-opacity: 1;
    }
  }
  &__frame-preview {
    width: 100%;
    height: 100%;
    @include object-fit(cover);
  }
  &__fake-btn {
    position: absolute;
    width: 68px;
    height: 48px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .ytp-large-play-button-bg {
      fill: #212121;
      fill-opacity: .8;
      transition: fill .2s, fill-opacity .2s;
    }
  }
  &__desc {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 17px 0;
  }
  &__name {
    font-size: 20px;
    font-weight: bold;
    padding-right: 10px;
  }
  &__service {
    font-family: $font-2;
    font-size: 20px;
    font-weight: normal;
  }
}

.testimonials {
  margin-bottom: 200px;
  @include media(1600px) {
    margin-bottom: 100px;
  }
  @include media(1200px) {
    margin-bottom: 50px;
  }
  &__title {
    text-align: center;
    margin-bottom: 70px;
    @include media(1024px) {
      margin-bottom: 35px;
    }
  }
}

.more-info {
  margin-bottom: 284px;
  position: relative;
  background-color: #1a1a1a;
  color: #fff;
  @include media(1024px) {
    margin-bottom: 0;
  }
  &__inner {
    padding: 74px 0 97px;
    margin: 0 auto;
    width: 100%;
    max-width: 830px;
    background-color: #1a1a1a;
    color: #fff;
    @include media(1024px) {
      padding: 74px 0 367px;
    }
    @include media(768px) {
      padding-top: 47px;
    }
  }
  &__title {
    margin-bottom: 24px;
    text-align: center;
  }
  &__sub-title {
    margin-bottom: 70px;
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    font-family: $font-2;
    @include media(1024px) {
      margin-bottom: 55px;
    }
  }
  &__form {
  }
  &__bg {
    position: absolute;
    right: -527px;
    bottom: -290px;
    pointer-events: none;
    @include media(1900px) {
      left: 58%;
    }
    @include media(1400px) {
      left: 63%;
      bottom: -275px;
    }
    @include media(1024px) {
      width: 732px;
      min-width: 732px;
      bottom: 0;
      left: 20%;
    }
  }
  .form {
    @include media(800px) {
      max-width: 600px;
      width: 100%;
      margin: 0 auto;
    }
    &__row {
      margin-bottom: 66px;
      display: flex;
      justify-content: space-between;
      @include media(800px) {
        margin-bottom: 0;
        flex-wrap: wrap;
      }
    }
    &__group {
      width: calc(50% - 35px);
      input {
        padding: 0 15px;
        width: 100%;
        height: 60px;
        background-color: #fff;
        font-size: 22px;
        font-family: $font-2;
        border: solid 3px rgba(229, 62, 42, 0.45);
      }
      @include media(800px) {
        width: 100%;
        margin-bottom: 40px;
      }
    }
    &__label {
      padding-left: 12px;
      margin-bottom: 13px;
      font-size: 22px;
      font-family: $font-2;
    }
    &__submit-wrap {
      text-align: center;
    }
    &__submit {
      padding: 0 74px;
      height: 60px;
      border: solid 3px #e53e2a;
      font-size: 20px;
      font-weight: bold;
      transition: background-color .3s, color .3s;
      &:hover{
        background-color: #e53e2a;
        color: #fff;
      }
    }
  }
}

.contacts {
  padding: 90px 0 120px;
  background-image: url("../img/contacts_bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  &__title {
    margin-bottom: 35px;
    text-align: center;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    @include media(1200px) {
      flex-direction: column-reverse;
    }
  }
  &__item {
    width: calc(50% - 10px);
    margin: 55px 20px 55px 0;
    @include media(1600px) {
      margin: 25px 20px 25px 0;
    }
    @include media(1200px) {
      width: 100%;
      text-align: center;
      margin: 30px 0;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  &__item-title {
    margin-bottom: 40px;
    font-size: 32px;
    font-weight: bold;
    @include media(1600px) {
      margin-bottom: 15px;
      font-size: 28px;
    }
  }
  &__item-link {
    margin-bottom: 17px;
    display: inline-block;
    font-size: 42px;
    font-weight: bold;
    color: #e43e28;
    @include media(1600px) {
      font-size: 30px;
    }
    @include media(500px) {
      font-size: 24px;
    }
    &--mod {
      height: 79px;
      line-height: 79px;
      @include media(1200px) {
        height: auto;
        line-height: unset;
      }
    }
  }
  &__item-desc {
    font-size: 26px;
    font-weight: bold;
  }
  &__socials {
    display: flex;
    align-items: center;
    @include media(1200px) {
      justify-content: center;
    }
    a {
      display: inline-block;
      margin-right: 40px;
      @include media(768px) {
        margin-right: 25px;
        display: flex;
        align-items: center;
      }
      img {
        @include media(1450px) {
          width: 50px;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.info-text {
  padding-bottom: 30px;
  text-align: center;
  font-size: 16px;
  line-height: 1.21;
  letter-spacing: .05em;
  color: rgba(0, 0, 0, .7);
  font-family: 'Montserrat', sans-serif;
}

#map {
  height: calc(100vh - 163px);
}

.text-content {
  &--p1 {
    padding: 140px 0 120px;
    @include media(1600px) {
      padding: 100px 0 80px;
    }
    @include media(1200px) {
      padding: 60px 0 80px;
    }
    @include media(1024px) {
      padding: 30px 0 60px;
    }
  }
  &__title {
    margin-bottom: 114px;
    text-align: center;
    @include media(1600px) {
      margin-bottom: 70px;
    }
    @include media(1024px) {
      margin-bottom: 40px;
    }
  }
  &__title2 {
    margin-bottom: 130px;
    text-align: center;
    @include media(1600px) {
      margin-bottom: 70px;
    }
    @include media(1024px) {
      margin-bottom: 40px;
    }
  }
  &__text {
    margin-bottom: 90px;
    text-align: center;
    font-family: $font-2;
    font-size: 18px;
    line-height: 1.44;
    @include media(1600px) {
      margin-bottom: 50px;
    }
    @include media(1024px) {
      margin-bottom: 20px;
    }
  }
  &__img {
    width: 100%;
    max-width: 700px;
    display: block;
    margin: 0 auto;
    height: auto;
    &--full {
      max-width: 100%;
    }
    &.mobile {
      width: 112%;
      position: relative;
      left: -6%;
      max-width: unset;
    }
  }
}

.info-grid {
  padding: 145px 0;
  @include media(1600px) {
    padding: 90px 0;
  }
  &--p1 {
    padding: 110px 0 145px;
    @include media(1600px) {
      padding: 90px 0 130px;
    }
    @include media(1024px) {
      padding: 90px 0;
    }
  }
  &--p2 {
    padding: 20px 0 300px;
    @include media(1600px) {
      padding: 90px 0;
    }
  }
  &__title {
    margin-bottom: 144px;
    text-align: center;
    @include media(1600px) {
      margin-bottom: 80px;
    }
    @include media(1600px) {
      margin-bottom: 60px;
    }
    @include media(1024px) {
      margin-bottom: 40px;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    @include media(1024px) {
      justify-content: space-around;
    }
  }
  &__item {
    text-align: center;
    width: calc(33.33% - 66px);
    margin: 40px 99px 40px 0;
    font-family: $font-2;
    @include media(1600px) {
      width: 33%;
      margin: 25px 0;
      min-width: 320px;
      padding: 0 20px;
    }
    @include media(500px) {
      width: 100%;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
  &__item-icon {
    display: block;
    margin: 0 auto 30px auto;
    height: 75px;
    width: auto;
    max-width: 100%;
  }
  &__item-title {
    margin-bottom: 28px;
    font-size: 20px;
    font-weight: bold;
    @include media(1600px) {
      margin-bottom: 10px;
    }
  }
  &__item-text {
    font-size: 16px;
    line-height: 1.44;
  }
  &__cta-wrap {
    margin-top: 135px;
    text-align: center;
    @include media(1600px) {
      margin-top: 30px;
    }
  }
}

.compare {
  padding: 200px 0 300px;
  @include media(1950px) {
    padding: 100px 0 150px;
  }
  @include media(1024px) {
    padding: 60px 0 70px;
  }
  .container {
    @include media(1950px) {
      max-width: 700px;
    }
  }
  &__title {
    margin-bottom: 106px;
    text-align: center;
    @include media(1950px) {
      margin-bottom: 60px;
    }
    @include media(1024px) {
      margin-bottom: 40px;
    }
  }
  &__tabs-wrap {
    overflow: hidden;
    position: relative;
    margin-bottom: 70px;
    border: solid 3px #e53e2a;
    @include media(1950px) {
      margin-bottom: 40px;
    }
    //@include media(1300px){
    //  margin-bottom: 40px;
    //}
    @include media(1024px) {
      margin-bottom: 20px;
    }
    &:hover:before {
      opacity: 0;
      pointer-events: none;
    }
    &:before {
      content: "";
      display: block;
      background-image: url("/img/swipe.png");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      z-index: 2;
      width: 100px;
      height: 100px;
      top: calc(50% - 50px);
      right: calc(50% - 50px);
      filter: invert(26%) sepia(86%) saturate(7455%) hue-rotate(356deg) brightness(102%) contrast(118%);
      animation: swipe infinite 3s;
      transition: opacity .3s linear;
    }
  }
  .twentytwenty-container {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .twentytwenty-horizontal .twentytwenty-handle:after, .twentytwenty-horizontal .twentytwenty-handle:before, .twentytwenty-vertical .twentytwenty-handle:after, .twentytwenty-vertical .twentytwenty-handle:before {
    background-color: #e53e2a;
  }
  .twentytwenty-handle {
    cursor: ew-resize;
    border-color: #e53e2a;
    background-color: #e53e2a;
    transform: scale(1.5);
    //width: 100px;
    //height: 44px;
    &:before, &:after {
      box-shadow: 0 3px 0 #e53e2a, 0 0 12px rgba(51, 51, 51, .5);
    }
    .twentytwenty-left-arrow {
      //margin-left: -30px;
      border-right-color: #fff;
    }
    .twentytwenty-right-arrow {
      //margin-right: -30px;
      border-left-color: #fff;
    }
  }
  .twentytwenty-overlay {
    display: none;
  }
  &__tab {
    opacity: 0;
    &--init {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
    }
    &--active {
      opacity: 1;
      position: relative;
      z-index: 1;
    }
  }
  &__nav-list {
    display: flex;
    justify-content: center;
    @include media(1024px) {
      display: block;
    }
  }
  &__nav-item {
    padding: 40px 20px 20px;
    border-radius: 12px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
    text-align: center;
    border: solid 10px transparent;
    transition: border-color .3s;
    cursor: pointer;
    @include media(1950px) {
      padding: 15px;
    }
    @include media(1200px) {
      border: solid 4px transparent;
    }
    &--3 {
      width: calc(33.33% - 50px);
      margin-right: 75px;
      @include media(1950px) {
        width: calc(33.33% - 20px);
        margin-right: 30px;
      }
      @include media(1024px) {
        width: 100%;
        margin: 0 0 20px 0;
      }
      &:last-child {
        @include media(1024px) {
          margin-bottom: 0;
        }
      }
    }
    &--2 {
      width: calc(50% - 13px);
      margin-right: 26px;
      @include media(1024px) {
        width: 100%;
        margin: 0 0 20px 0;
      }
      &:last-child {
        @include media(1024px) {
          margin-bottom: 0;
        }
      }
    }
    &:last-child {
      margin-right: 0;
    }
    &--active, &:hover {
      border-color: #e43e28;
    }
    &:nth-child(2) {
      .compare__nav-price {
        font-size: 32px;
        @include media(1950px) {
          font-size: 26px;
        }
        span {
          font-size: 32px;
          @include media(1950px) {
            font-size: 28px;
          }
        }
      }
    }
  }
  &__nav-title {
    margin-bottom: 23px;
    font-size: 24px;
    font-weight: bold;
    font-family: $font-2;
    @include media(1950px) {
      font-size: 18px;
      margin-bottom: 15px;
    }
    @include media(1200px) {
      //font-size: 20px;
      //margin-bottom: 15px;
    }
  }
  &__nav-price {
    margin-bottom: 26px;
    font-size: 24px;
    font-weight: bold;
    transition: font-size 1s;
    @include media(1950px) {
      font-size: 22px;
      margin-bottom: 20px;
    }
    span {
      font-size: 28px;
      transition: font-size 1s;
      @include media(1950px) {
        font-size: 24px;
      }
    }
  }
  &__nav-cta {
    @include media(1200px) {
      height: 50px;
      line-height: 50px;
    }
  }
}

.w-popup {
  position: fixed;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: #0c0d13;
  color: #fff;
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s linear;
  @include media(1024px) {
    justify-content: flex-end;
  }
  @include media-h(500px) {
    justify-content: center;
  }
  &.active {
    opacity: 1;
    pointer-events: all;
  }
  &__close {
    position: absolute;
    z-index: 2;
    top: 25px;
    right: 50px;
    display: block;
    width: 44px;
    height: 44px;
    background-image: url("/img/close.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    transition: opacity .3s;
    @include media(1024px) {
      width: 25px;
      height: 25px;
      top: 20px;
      right: 20px;
    }
    @include media(768px) {
      //top: 10px;
      //right: 10px;
    }
    &:hover {
      opacity: .7;
    }
  }
  &__inner {
    position: relative;
    max-width: 80%;
    margin: 0 auto;
    @include media(1024px) {
      max-width: 100%;
      padding-bottom: 20px;
    }
    @include media-h(500px) {
      padding-bottom: 0;
    }
  }
  &__slider {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  &__swiper-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  &__slide {
    overflow: hidden;
    width: 100%;
    height: calc(100vh - 300px);
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include media-h(500px) {
      height: calc(80vh);
    }
    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto;
    }
  }
  &__counter {
    position: absolute;
    top: 80px;
    right: 50px;
    margin-bottom: 29px;
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    pointer-events: none;
    @include media(1024px) {
      display: none
    }
    span {
      font-size: 36px;
    }
    &--mob {
      display: none;
      margin-bottom: 0;
      @include media(1024px) {
        position: unset;
        display: block;
      }
    }
  }
  &__caption {
    margin-top: 29px;
    text-align: center;
    font-size: 51px;
    font-weight: bold;
    @include media(1600px) {
      font-size: 32px;
    }
    span {
      font-family: $font-2;
      font-size: 34px;
      @include media(1600px) {
        font-size: 28px;
      }
      @include media(1024px) {
        display: block;
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
  }
  &__nav {
    @include media(1024px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__btn {
    position: absolute;
    @include media(1024px) {
      position: relative;
    }
    &.slider-btn {
      top: 50%;
      transform: translateY(-50%);
      @include media(1024px) {
        top: unset;
        transform: unset;
      }
      &--prev {
        left: -64px;
        @include media(1024px) {
          left: unset;
        }
      }
      &--next {
        right: -64px;
        @include media(1024px) {
          right: unset;
        }
      }
    }
  }
  &__bottom {
    @include media(1024px) {
      width: 95%;
      margin: 0 auto;
    }
    @include media-h(500px) {
      display: none;
    }
  }
}

.table {
  padding-bottom: 170px;
  @include media(1950px) {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  @include media(1024px) {
    padding-bottom: 0;
    padding-top: 0;
  }
  .container {
    max-width: 900px;
  }
  &__title {
    margin-bottom: 129px;
    text-align: center;
    @include media(1950px) {
      margin-bottom: 60px;
    }
    @include media(1024px) {
      margin-bottom: 40px;
    }
  }
  &__inner {
    padding: 82px 107px;
    border-radius: 12px;
    box-shadow: 0 0 15px 0 rgba(228, 62, 40, 0.29);
    border: solid 10px #e43e28;
    @include media(1950px) {
      padding: 40px 40px 30px;
      border: solid 4px #e43e28;
    }
    @include media(1024px) {
      padding: 40px 20px 60px;
    }
  }
  &__inner-title {
    text-align: center;
    margin-bottom: 65px;
    @include media(1950px) {
      margin-bottom: 35px;
    }
  }
  &__list {
    margin-bottom: 91px;
    @include media(1950px) {
      margin-bottom: 40px;
    }
    @include media(1024px) {
      margin-bottom: 51px;
    }
  }
  &__item {
    margin-bottom: 20px;
    padding: 6px 0;
    display: flex;
    align-items: center;
    font-size: 26px;
    font-weight: normal;
    font-family: $font-2;
    border-bottom: 2px solid rgba(112, 112, 112, .3);
    @include media(1950px) {
      font-size: 18px;
      margin-bottom: 5px;
      padding: 4px 0;
    }
    &.table__head {
      margin-bottom: 60px;
      align-items: unset;
      font-size: 32px;
      font-weight: bold;
      border: none;
      @include media(1950px) {
        font-size: 18px;
        margin-bottom: 20px;
      }
      @include media(768px) {
        font-size: 14px;
      }
      .table__col {
        @include media(1024px) {
          text-align: right;
        }
        @include media(500px) {
          //transform: rotate(45deg);
        }
        &:first-child {
          @include media(1024px) {
            font-size: 0;
          }
        }
      }
    }
  }
  &__col {
    width: 25%;
    text-align: center;
    @include media(1024px) {
      width: 20%;
    }
    &:first-child {
      width: 50%;
      text-align: left;
      @include media(1024px) {
        width: 60%;
      }
    }
  }
  &__mark {
    display: block;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    margin: 0 auto;
    @include media(1950px) {
      width: 20px;
      height: 20px;
    }
    &--true {
      background-color: #e43e28;
    }
    &--false {
      border: solid 2px rgba(112, 112, 112, .3);

    }
  }
  &__cta-wrap {
    text-align: center;
  }
  &__cta {
    display: inline-block;
    padding: 0 39px;
    height: 60px;
    line-height: 60px;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    background-color: #e53e2a;
    @include media(1950px) {
      padding: 0 19px;
      height: 50px;
      line-height: 50px;
      font-size: 20px;
    }
  }
}

.fixed-buttons {
  position: fixed;
  bottom: 20px;
  right: 50px;
  z-index: 9;
  display: block;
  width: 79px;
  height: 79px;
  border-radius: 50%;
  pointer-events: none;
  @include media(1600px) {
    right: 20px;
    width: 59px;
    height: 59px;
  }
  @include media(1024px) {
    bottom: 10px;
  }
  .main-btn {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url("/img/mail.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    &:before {
      content: '';
      opacity: 0;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("/img/fixed-close.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      transition: opacity .3s;
    }
    &.active {
      &:before {
        opacity: 1;
      }
    }
  }
  .contact-buttons {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    //transform: translateX(150px);
    //transition: transform .5s;
    &.active .contact-btn {
      transform: translateX(0);
    }
  }
  .contact-btn, .main-btn {
    pointer-events: all;
    margin-bottom: 13px;
    display: block;
    width: 100%;
    height: 79px;
    border-radius: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    @include media(1600px) {
      height: 59px;
    }
  }
  .contact-btn {
    transform: translateX(150px);
    transition: transform .5s;
    &--phone {
      background-image: url("/img/phone.png");
    }
    &--inst {
      background-image: url("/img/instagram.png");
    }
    &--fb {
      background-image: url("/img/facebook.png");
    }
    &--telegram {
      background-image: url("/img/telegram.png");
    }
    &--viber {
      background-image: url("/img/viber.png");
    }
    &--form {
      background-image: url("/img/mail.png");
    }
  }
}

.form-popup {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  pointer-events: none;
  opacity: 0;
  transition: opacity .3s linear;
  &.active {
    opacity: 1;
    pointer-events: all;
  }
  &__close {
    position: absolute;
    z-index: 1;
    top: 25px;
    right: 50px;
    display: block;
    width: 44px;
    height: 44px;
    background-image: url("/img/close-b.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    transition: opacity .3s;
    @include media(1024px) {
      width: 25px;
      height: 25px;
      top: 20px;
      right: 20px;
    }
    &:hover {
      opacity: .7;
    }
  }
  &__inner {
    display: flex;
  }
  &__col {
    height: 100vh;
    &--form {
      padding: 0 45px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 45%;
      @include media(1200px) {
        width: 100%;
      }
    }
    &--img {
      position: relative;
      width: 55%;
      background-color: #E43E28;
      @include media(1200px) {
        display: none;
      }
    }
  }
  &__bg {
    position: absolute;
    top: calc(50% + 100px);
    transform: translateY(-50%);
    left: 80px;
    pointer-events: none;
  }
  &__form-wrap {
    width: 465px;
    max-width: 100%;
    margin-left: auto;
    @include media(1200px) {
      margin: 0 auto;
      text-align: center;
    }
  }
  &__title {
    margin-bottom: 20px;
    font-size: 65px;
    font-weight: bold;
    line-height: 0.88;
    @include media(768px) {
      font-size: 34px;
    }
  }
  &__sub-title {
    margin-bottom: 35px;
    font-size: 24px;
    font-weight: bold;
    @include media(768px) {
      max-width: 395px;
      margin: 0 auto 35px;
    }
  }
  .form-group {
    width: 100%;
    margin-bottom: 30px;
    input {
      padding: 0 35px;
      width: 100%;
      height: 75px;
      border: solid 2px #e53e2a;
      @include media(768px) {
        height: 65px;
      }
    }
  }
  .form-submit-wrap {
    text-align: right;
    @include media(1200px) {
      text-align: center;
    }
  }
  .form-submit {
    padding: 0 120px;
    height: 90px;
    background-color: #e53e2a;
    font-size: 20px;
    font-weight: bold;
    font-family: $font-2;
    color: #fff;
    border: solid 3px #e53e2a;
    transition: background-color .3s, color .3s;
    &:hover{
      background-color: transparent;
      color: #e53e2a;
    }
    @include media(768px) {
      width: 100%;
      padding: 0 15px;
    }
  }
}

.preloader {
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  color: #fff;
  background-color: #000;
  transition: opacity .3s linear;
  pointer-events: none;
  &__inner {
    display: inline-block;
    margin: 0 auto;
    img {
      width: 200px;
      display: block;
      margin: 0 auto 35px;
      @include media(1024px) {
        width: 160px;
        margin: 0 auto 30px;
      }
    }
  }
  &__progress {
    max-width: 100%;
    width: 320px;
    height: 2px;
    margin: 0 auto 15px;
    background-color: rgba(254, 254, 254, .5);
    @include media(1024px) {
      width: 150px;
    }
  }
  &__progress-inner {
    height: 100%;
    width: 0;
    background-color: #fff;
  }
  &__text {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    @include media(1024px) {
      font-size: 16px;
    }
  }
}

.lazyImg {
  opacity: 0;
  &.show {
    opacity: 1;
  }
}

@keyframes swipe {
  0% {
    transform: translateX(-75px);
  }

  50% {
    transform: translateX(75px);
  }

  100% {
    transform: translateX(-75px);
  }
}

//.works{
//  .lazyImg{
//    transition: opacity .3s;
//  }
//}
//
//.w-popups{
//  .lazyImg{
//    transition: opacity .3s;
//  }
//}
