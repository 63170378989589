.container {
  width: 94%;
  margin: 0 auto;
  max-width: 1475px;
  &--1360{
    max-width: 1360px;
  }
  &--1200{
    max-width: 1200px;
  }
  &--1100{
    max-width: 1100px;
  }
  &--big{
    max-width: 1920px;
  }

  @media (max-width: $lg) {
  }

  @media (max-width: $md) {
  }

  @media (max-width: $sm) {
  }
}
